
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: grid;
  place-items: center;
  height: 100vh;
  font-family: 'Oswald', sans-serif;
  background: linear-gradient(to right, #635acf, #915fc7);

}

.header{
    
  padding: 1rem 0;
  color: #fff;
}

.input{
    width: 15rem;
    height:2rem;
    border-radius:8px;
    border: none;
    padding: 10px;
    font-weight: 600;
    font-size: small;
    
}


.input::placeholder{
    color: #80747f;
  }

.plus{
    width: 5rem;
    height: 1.5rem;
    margin-left: 5rem;
    margin-top: 3rem;
    background-color: yellowgreen;
    border-radius: 8px;
    border: none;
}

.olist{
    margin-left: 1rem;
    padding: 1rem;
    color: #fff;
    font-size: 20px;
    letter-spacing: 2px;
}